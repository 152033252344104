@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

a {
  text-decoration: inherit;
  color: inherit;
}
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-right: 0px !important;
  min-height: 100vh;
  margin: 0px;
  background-color: white !important;
}

.MuiInputBase-root {
  background-color: #ebebeb;
}
.MuiInputLabel-root.MuiInputLabel-shrink {
  background: #fff;
  padding: 0px 7px;
  border-radius: 25px;
  margin-left: -6px;
}
.MuiInputLabel-root:not(.MuiInputLabel-shrink) {
  color: "#a3a3a3";
}

.MuiDrawer-paper {
  border: none !important;
}
.MuiDataGrid-root {
  border: none !important;
}
.MuiAppBar-root {
  border: none !important;
}

.MuiInputBase-root:not(.Mui-focused):not(.Mui-error) fieldset {
  border: none;
}

@media (min-width: 1350px) {
  .MuiContainer-root {
    max-width: 1350px !important;
  }
}
.region-select fieldset {
  border: none !important;
}
.MuiMenu-paper.MuiPaper-elevation8 {
  transform: translate(0px, 5px) !important;
}

.MuiDrawer-paper {
  background-color: white !important;
}
.MuiToolbar-root {
  background-color: white;
  min-height: 56px !important;
}

.ellipsis-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: break-spaces;
}

.ellipsis-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: break-spaces;
}

textarea {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.MuiDialog-paper {
  margin: 8px !important;
}
